import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import {
  ExpectedTable,
  ExpectedTableBody,
  ExpectedTableHead,
  ExpectedTableRow,
  PendingTable,
  WithoutSolutionTableBody,
  WithoutSolutionTableHead,
  WithoutSolutionTableRow,
  StyledWithoutSolutionTable,
} from "./styles";
import { Button } from "../../../components/Button";
import { StatusIcon } from "../../../components/Icons/StatusIcon";
import {
  SortArrowDown,
  SortArrowUp,
} from "../../../components/StyledIconButton";

export const PendingEnchantTable = ({
  complaints,
  handleShowDetail,
  sort,
  handleSort,
}) => {
  return (
    <PendingTable>
      <TableHead>
        <TableRow>
          <TableCell className="titleStatus"></TableCell>
          <TableCell className="titleId">Quebra Nº</TableCell>
          <TableCell className="titleOrderId">Pedido</TableCell>
          <TableCell className="titleDate">
            Data{" "}
            {sort === "desc" ? (
              <SortArrowDown onClick={handleSort} />
            ) : (
              <SortArrowUp onClick={handleSort} />
            )}
          </TableCell>
          <TableCell className="titleValue">Valor</TableCell>
          <TableCell className="titleNegotiation">Tratativa</TableCell>
          <TableCell className="titleUpdated">Última Atualização</TableCell>
          <TableCell className="titleAction"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {complaints?.map((complaint) => (
          <TableRow key={complaint?.id}>
            <TableCell className="contentStatus">
              <StatusIcon statusId={complaint?.status?.id} />
            </TableCell>
            <TableCell className="contentId">{complaint?.id}</TableCell>
            <TableCell className="contentOrderId">
              {complaint?.order_id}
            </TableCell>
            <TableCell className="contentDate">
              <span>
                <span className="date">{complaint?.createdDate}</span>
                <span className="time">{complaint?.createdTime}</span>
              </span>
            </TableCell>

            <TableCell className="contentValue">
              {complaint?.complaintValue}
            </TableCell>

            <TableCell className="contentNegotiation">
              {complaint?.action}
            </TableCell>

            <TableCell className="contentUpdated">
              <span>
                <span className="date">{complaint?.updatedDate}</span>
                <span className="time">{complaint?.updatedTime}</span>
              </span>
            </TableCell>
            <TableCell className="contentAction">
              <Button
                btn="Secondary"
                onClick={() => handleShowDetail(complaint?.id)}
              >
                Ver detalhes
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </PendingTable>
  );
};

export const ExpectedBreakTable = ({ complaints, handleShowDetail }) => {
  return (
    <ExpectedTable>
      <ExpectedTableHead>
        <ExpectedTableRow>
          <TableCell className="status"></TableCell>
          <TableCell className="complaintId">Quebra nº</TableCell>
          <TableCell className="orderId">Pedido</TableCell>
          <TableCell className="clientName">Cliente</TableCell>
          <TableCell className="complaintDate">Data da quebra</TableCell>
          <TableCell className="value">Valor</TableCell>
          <TableCell className="updated">Última atualização</TableCell>
          <TableCell className="action"></TableCell>
        </ExpectedTableRow>
      </ExpectedTableHead>
      <ExpectedTableBody>
        {complaints?.map((complaint) => (
          <ExpectedTableRow key={complaint.complaintId}>
            <TableCell className="status">
              <StatusIcon statusId={1} />
            </TableCell>
            <TableCell className="complaintId">
              {complaint.complaintId}
            </TableCell>
            <TableCell className="orderId">{complaint.orderId}</TableCell>
            <TableCell className="clientName">{complaint.clientName}</TableCell>
            <TableCell className="complaintDate">
              <span>
                <span className="date">{complaint.createdDate}</span>
                <span className="time">{complaint.createdTime}</span>
              </span>
            </TableCell>
            <TableCell className="value">{complaint.complaintValue}</TableCell>
            <TableCell className="updated">
              <span>
                <span className="date">{complaint.updatedDate}</span>
                <span className="time">{complaint.updatedTime}</span>
              </span>
            </TableCell>
            <TableCell className="action">
              <Button
                btn="secondary"
                onClick={() => handleShowDetail(complaint.complaintId)}
              >
                Tratar quebra
              </Button>
            </TableCell>
          </ExpectedTableRow>
        ))}
      </ExpectedTableBody>
    </ExpectedTable>
  );
};

export const DriverBreakTable = ({ complaints, handleShowDetail }) => {
  return (
    <ExpectedTable>
      <ExpectedTableHead>
        <ExpectedTableRow>
          <TableCell className="status"></TableCell>
          <TableCell className="complaintId">Quebra nº</TableCell>
          <TableCell className="orderId">Pedido</TableCell>
          <TableCell className="clientName">Cliente</TableCell>
          <TableCell className="complaintDate">Data da quebra</TableCell>
          <TableCell className="value">Valor</TableCell>
          <TableCell className="updated">Última atualização</TableCell>
          <TableCell className="action"></TableCell>
        </ExpectedTableRow>
      </ExpectedTableHead>
      <ExpectedTableBody>
        {complaints?.map((complaint) => (
          <ExpectedTableRow key={complaint.complaintId}>
            <TableCell className="status">
              <StatusIcon statusId={1} />
            </TableCell>
            <TableCell className="complaintId">
              {complaint.complaintId}
            </TableCell>
            <TableCell className="orderId">{complaint.orderId}</TableCell>
            <TableCell className="clientName">{complaint.clientName}</TableCell>
            <TableCell className="complaintDate">
              <span>
                <span className="date">{complaint.createdDate}</span>
                <span className="time">{complaint.createdTime}</span>
              </span>
            </TableCell>
            <TableCell className="value">{complaint.complaintValue}</TableCell>
            <TableCell className="updated">
              <span>
                <span className="date">{complaint.updatedDate}</span>
                <span className="time">{complaint.updatedTime}</span>
              </span>
            </TableCell>
            <TableCell className="action">
              <Button
                btn="secondary"
                onClick={() => handleShowDetail(complaint.complaintId)}
              >
                Tratar quebra
              </Button>
            </TableCell>
          </ExpectedTableRow>
        ))}
      </ExpectedTableBody>
    </ExpectedTable>
  );
};

export const ToApproveTable = ({ complaints, handleShowDetail }) => {
  return (
    <ExpectedTable>
      <ExpectedTableHead>
        <ExpectedTableRow>
          <TableCell className="status"></TableCell>
          <TableCell className="complaintId">Quebra nº</TableCell>
          <TableCell className="orderId">Pedido</TableCell>
          <TableCell className="clientName">Cliente</TableCell>
          <TableCell className="complaintDate">Data da quebra</TableCell>
          <TableCell className="value">Valor</TableCell>
          <TableCell className="updated">Última atualização</TableCell>
          <TableCell className="action"></TableCell>
        </ExpectedTableRow>
      </ExpectedTableHead>
      <ExpectedTableBody>
        {complaints?.map((complaint) => (
          <ExpectedTableRow key={complaint.complaintId}>
            <TableCell className="status">
              <StatusIcon statusId={1} />
            </TableCell>
            <TableCell className="complaintId">
              {complaint.complaintId}
            </TableCell>
            <TableCell className="orderId">{complaint.orderId}</TableCell>
            <TableCell className="clientName">{complaint.clientName}</TableCell>
            <TableCell className="complaintDate">
              <span>
                <span className="date">{complaint.createdDate}</span>
                <span className="time">{complaint.createdTime}</span>
              </span>
            </TableCell>
            <TableCell className="value">{complaint.complaintValue}</TableCell>
            <TableCell className="updated">
              <span>
                <span className="date">{complaint.updatedDate}</span>
                <span className="time">{complaint.updatedTime}</span>
              </span>
            </TableCell>
            <TableCell className="action">
              <Button
                btn="secondary"
                onClick={() => handleShowDetail(complaint.complaintId)}
              >
                Avaliar
              </Button>
            </TableCell>
          </ExpectedTableRow>
        ))}
      </ExpectedTableBody>
    </ExpectedTable>
  );
};

export const WithoutSolutionTable = ({ complaints, handleShowDetail }) => {
  return (
    <StyledWithoutSolutionTable>
      <WithoutSolutionTableHead>
        <WithoutSolutionTableRow>
          <TableCell className="status"></TableCell>
          <TableCell className="complaintId">Quebra nº</TableCell>
          <TableCell className="orderId">Pedido</TableCell>
          <TableCell className="clientName">Cliente</TableCell>
          <TableCell className="origin">Origem</TableCell>
          <TableCell className="complaintDate">Data da quebra</TableCell>
          <TableCell className="value">Valor</TableCell>
          <TableCell className="updated">Última atualização</TableCell>
          <TableCell className="action"></TableCell>
        </WithoutSolutionTableRow>
      </WithoutSolutionTableHead>
      <WithoutSolutionTableBody>
        {complaints?.map((complaint) => (
          <WithoutSolutionTableRow key={complaint.complaintId}>
            <TableCell className="status">
              <StatusIcon statusId={1} />
            </TableCell>
            <TableCell className="complaintId">
              {complaint.complaintId}
            </TableCell>
            <TableCell className="orderId">{complaint.orderId}</TableCell>
            <TableCell className="clientName">{complaint.clientName}</TableCell>
            <TableCell className="origin">{complaint.origin}</TableCell>
            <TableCell className="complaintDate">
              <span>
                <span className="date">{complaint.createdDate}</span>
                <span className="time">{complaint.createdTime}</span>
              </span>
            </TableCell>
            <TableCell className="value">{complaint.complaintValue}</TableCell>
            <TableCell className="updated">
              <span>
                <span className="date">{complaint.updatedDate}</span>
                <span className="time">{complaint.updatedTime}</span>
              </span>
            </TableCell>
            <TableCell className="action">
              <Button
                btn="secondary"
                onClick={() => handleShowDetail(complaint.complaintId)}
              >
                Tratar quebra
              </Button>
            </TableCell>
          </WithoutSolutionTableRow>
        ))}
      </WithoutSolutionTableBody>
    </StyledWithoutSolutionTable>
  );
};
