import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  InputAdornment,
} from "@material-ui/core";
import { ContainerPage } from "../../../components/ContainerPage";
import { H1 } from "../../../components/Heading/Heading";
import {
  CheckAllContent,
  ClassifiedAllButton,
  ClearFilterButtonContent,
  DivSearch,
  DivTitle,
  FilterContainer,
  FilterContent,
  FilterContentHeader,
  FilterLabel,
  FilterLabelDescription,
  FilterTitleLabel,
  SearchInput,
  TableContainer,
} from "./style";
import SearchIcon from "@material-ui/icons/Search";

import { useCallback, useState } from "react";
import { SelectDateSearch } from "./SelectDateSearch";
import { BreakTable } from "./tables";

import OrderServices from "../../../services/orderServices";
import { toast } from "react-toastify";
import { useEffect } from "react";
import PageLoader from "../../../components/PageLoader";
import { CustomLineProgress } from "../../../components/CustomLineProgress";
import { useDebaunce } from "../../../utils/debaunce";
import { useRef } from "react";
import { useMemo } from "react";
import { ModalBodySeeMore, ResolveSelectedModal } from "./modalBody";
import { LoadingBackDrop } from "../../../components/LoadingBackDrop";
import { AlertModal } from "../../../components/AlertModal";
import { ModalImage } from "../../../components/Modals/ModalImage";
import { SelectProductsBase } from "./selectProductsBase";
import { SelectStatus } from "./selectStatus";
import { SelectReason } from "./selectReason";
import {
  formatReason,
  formatResponsible,
  formatProductBase,
  formatItem,
  formatClassifier,
} from "./formatData";
import { SelectResponsible } from "./selectResponsible";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "../../../components/Button";
import { SelectOrderBy } from "./selectOrderBy";
import { loggedInUserInfo } from "../../../utils/auth";
import { SelectClassifier } from "./selectClassifier";
import { SelectOrder } from "./selectOrder";

const filterResponsibleList = [4];

const statusReference = {
  all: "Todos",
  classified: "Classificados",
  unclassified: "Não classificados",
};

export const BreakAnalysis = () => {
  const [selectedSearchDateFrom, setSelectedSearchDateFrom] = useState(() => {
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);
    return todayDate.setHours(16, 1, 0, 0);
  });
  const [selectedSearchDateTo, setSelectedSearchDateTo] = useState(
    new Date().setHours(16, 0, 0, 0)
  );
  const [openSeeMore, setOpenSeeMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [items, setItems] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [responsibleReasonList, setResponsibleReasonList] = useState([]);
  const [selectedResponsible, setSelectedResponsible] = useState(0);
  const [responsibleObservation, setResponsibleObservation] = useState("");
  const [itemDetail, setItemDetail] = useState({});
  const [statusFilters, setStatusFilters] = useState("all");
  const [reasonFilters, setReasonFilters] = useState([]);
  const [responsibleFilters, setResponsibleFilters] = useState([]);
  const searchText = useRef("");
  const [listItems, setListItems] = useState([]);
  const [openResolveSelected, setOpenResolveSelected] = useState(false);
  const [submitType, setSubmitType] = useState("");
  const [productsBase, setProductsBase] = useState([]);
  const [selectedProductBase, setSelectedProductBase] = useState([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState("deliveryDate");
  const [classifierFilters, setClassifierFilters] = useState([]);
  const [classifierList, setClassifierList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("asc");

  const getItemsAnalysis = async () => {
    try {
      setSearchLoading(true);
      const { data } = await OrderServices.getAnalysisList({
        deliveryDateFrom: new Date(selectedSearchDateFrom),
        deliveryDateTo: new Date(selectedSearchDateTo),
        q: searchText.current.value?.trim(),
        orderBy: selectedOrderBy,
        sort: selectedOrder,
      });
      const { data: reasonData } = await OrderServices.getReasonList();
      const { data: responsibleReasonData } =
        await OrderServices.getResponsibleReasonList();
      setItems(formatItems(data || []));
      setReasonList(reasonData?.map((reason) => formatReason(reason)));
      setResponsibleReasonList(
        responsibleReasonData
          .filter((reason) => !filterResponsibleList.includes(reason.id))
          .map((responsible) => formatResponsible(responsible))
      );
    } catch (error) {
      toast.error(`Erro ao carregar as quebras ${error}`);
    } finally {
      setLoading(false);
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    const getProductsBase = async () => {
      const { data } = await OrderServices.getProductsBase();
      setProductsBase(
        data?.results.map((product) => formatProductBase(product))
      );
    };
    getProductsBase();
  }, []);

  const getDebaunce = useDebaunce({ fn: getItemsAnalysis, delay: 800 });

  const formatItems = useCallback(
    (data) => {
      return data?.reduce((acc, item) => [...acc, formatItem(item)], []);
    },
    [items]
  );

  useMemo(() => {
    const classifiers = items?.reduce(
      (acc, item) =>
        item.classifier &&
        !acc.find((classifier) => classifier.name === item.classifiedBy)
          ? [...acc, formatClassifier(item.classifier)]
          : [...acc],
      []
    );
    setClassifierList(classifiers);
  }, [items]);

  useEffect(() => {
    let filtered = !selectedProductBase.length
      ? items
      : items?.filter((item) =>
          selectedProductBase.includes(item.productBaseId)
        );

    if (statusFilters !== "all") {
      filtered = filtered.filter(({ responsibleId }) =>
        statusFilters === "unclassified" ? !responsibleId : responsibleId
      );
    }

    if (reasonFilters.length) {
      filtered = filtered.filter((item) =>
        reasonFilters.includes(item?.complaintReason?.id)
      );
    }

    if (responsibleFilters.length) {
      filtered = filtered.filter((item) =>
        responsibleFilters.includes(item?.responsibleId)
      );
    }
    if (classifierFilters.length) {
      filtered = filtered.filter((item) =>
        classifierFilters.includes(item.classifier?.id)
      );
    }
    setListItems(filtered);
  }, [
    items,
    selectedProductBase,
    statusFilters,
    reasonFilters,
    responsibleFilters,
    classifierFilters,
  ]);

  useEffect(() => {
    getDebaunce();
  }, [
    selectedSearchDateFrom,
    selectedSearchDateTo,
    selectedOrderBy,
    selectedOrder,
  ]);

  const handleSearch = () => {
    getDebaunce();
  };

  const qtyCheckedItens = useMemo(() => {
    return listItems.filter(({ isChecked }) => isChecked).length;
  }, [listItems]);

  const handleChangeSearchDateFrom = (date) => {
    const dateFrom = new Date(date).setHours(0, 0, 0, 0);
    const dateTo = new Date(selectedSearchDateTo).setHours(0, 0, 0, 0);
    if (dateFrom > dateTo) {
      return toast.error("A data inicial não pode ser maior que a data final");
    }
    setSelectedSearchDateFrom(dateFrom);
  };

  const handleChangeSearchDateTo = (date) => {
    const dateFrom = new Date(selectedSearchDateFrom).setHours(0, 0, 0, 0);
    const dateTo = new Date(date).setHours(0, 0, 0, 0);
    if (dateTo < dateFrom) {
      return toast.error("A data final não pode ser menor que a data inicial");
    }
    setSelectedSearchDateTo(dateTo);
  };

  const handleOpenSeeMore = useCallback(
    async (itemId) => {
      try {
        showBackDrop();
        const { data: item } = await OrderServices.getAnalysisDetail({
          itemId,
        });
        const formattedItem = formatItem(item);
        setItemDetail(formattedItem);
        setSelectedResponsible(formattedItem?.responsibleId || 0);
        setResponsibleObservation(formattedItem?.responsibleObservation || "");
        setOpenSeeMore(true);
      } catch {
        toast.error("Erro ao carregar os dados");
      } finally {
        hideBackDrop();
      }
    },
    [openSeeMore]
  );
  const handleCloseSeeMore = useCallback(() => {
    setOpenSeeMore(false);
  }, [openSeeMore]);

  const handleChangeResponsible = (e) => {
    const selectValue = e.target.value;
    setSelectedResponsible(selectValue);
  };

  const handleChangeResponsibleObservation = useCallback(
    (e) => {
      const text = e.target.value;
      setResponsibleObservation(text);
    },
    [responsibleObservation]
  );
  const showAlert = () => {
    setOpenAlert(true);
  };

  const closeAlert = () => {
    if (submitType === "one") {
      const { id: itemId } = itemDetail;
      const responsibleName = responsibleReasonList.find(
        ({ id }) => id === selectedResponsible
      )?.name;
      const userInfo = loggedInUserInfo();
      const updatedItems = items.map((item) =>
        item.id === itemId && !item?.classification
          ? {
              ...item,
              responsibleId: selectedResponsible,
              responsibleName: responsibleName,
              classifiedBy: userInfo.user_name,
              classifier: {
                ...item.classifier,
                id: userInfo.user_id,
                name: userInfo.user_name,
              },
            }
          : item
      );
      setItems(updatedItems);
    }

    if (submitType === "many") {
      searchText.current.value = "";
      getItemsAnalysis();
    }
    handleCloseSeeMore();
    handleCloseResolveSelected();
    setOpenAlert(false);
  };

  const showBackDrop = useCallback(() => {
    setSubmitLoading(true);
  }, [submitLoading]);

  const hideBackDrop = () => {
    setSubmitLoading(false);
  };

  const handleOpenImage = useCallback(() => {
    setOpenModalImage(true);
  }, [openModalImage]);

  const handleCloseImage = useCallback(() => {
    setOpenModalImage(false);
  }, [openModalImage]);

  const handleClearFilter = () => {
    setStatusFilters("all");
    setReasonFilters([]);
    setResponsibleFilters([]);
    setSelectedProductBase([]);
    handleClearSelectedProductBase();
    handleClearSelectedReason();
    handleClearSelectedResponsible();
    handleClearSelectedClassifier();
  };

  const handleCheckItem = useCallback(
    (itemId) => {
      setListItems(
        listItems?.map((item) =>
          item?.id === itemId ? { ...item, isChecked: !item.isChecked } : item
        )
      );
    },
    [listItems]
  );

  const handleCheckAllItem = useCallback(
    (e) => {
      const checked = e.target.checked;
      const updated = listItems.map((item) => {
        item.isChecked = checked;
        return item;
      });
      setListItems(updated);
    },
    [listItems]
  );

  const handleResolveSelected = useCallback(() => {
    setOpenResolveSelected(true);
  }, [openResolveSelected]);

  const handleCloseResolveSelected = useCallback(() => {
    setOpenResolveSelected(false);
    setSelectedResponsible(0);
    setResponsibleObservation("");
  }, [openResolveSelected]);

  const handleSubmit = async (typeSubmit) => {
    if (!selectedResponsible) {
      return toast.error("Selecione um responsável");
    }
    if (!responsibleObservation) {
      return toast.error(
        "O comentário é obrigatório para classificar a quebra"
      );
    }
    let data = [];

    if (typeSubmit === "many") {
      data = listItems
        .filter(({ isChecked }) => isChecked === true)
        .map(({ id }) => {
          return {
            item_id: id,
            responsible_id: selectedResponsible,
            responsible_observation: responsibleObservation,
          };
        });
    }

    if (typeSubmit === "one") {
      const { id } = itemDetail;
      data.push({
        item_id: id,
        responsible_id: selectedResponsible,
        responsible_observation: responsibleObservation,
      });
    }

    try {
      showBackDrop();
      await OrderServices.updateItemResponsible(data);
      setSubmitType(typeSubmit);
      showAlert();
    } catch {
      toast.error("Erro ao salvar responsável");
    } finally {
      hideBackDrop();
    }
  };

  const handleChangeSelectedProductBase = useCallback(
    (id) => {
      const updatedProductBase = productsBase.map((product) =>
        product.id === id
          ? { ...product, isChecked: !product.isChecked }
          : product
      );
      setProductsBase(updatedProductBase);
      setSelectedProductBase(
        updatedProductBase
          ?.filter(({ isChecked }) => isChecked)
          ?.reduce((acc, product) => [...acc, product.id], [])
      );
    },
    [productsBase, setProductsBase, setSelectedProductBase]
  );

  const handleClearSelectedProductBase = useCallback(() => {
    const updatedProductBase = productsBase.map((product) => ({
      ...product,
      isChecked: false,
    }));
    setProductsBase(updatedProductBase);
    setSelectedProductBase([]);
  }, [productsBase, setProductsBase, setSelectedProductBase]);

  const handleChangeStatusFilters = useCallback(
    (e) => {
      const { value } = e.target;
      setStatusFilters(value);
    },

    [statusFilters, setStatusFilters]
  );

  const handleChangeSelectedReason = useCallback(
    (id) => {
      const updatedReasonList = reasonList.map((reason) =>
        reason.id === id ? { ...reason, isChecked: !reason.isChecked } : reason
      );
      setReasonList(updatedReasonList);
      setReasonFilters(
        updatedReasonList
          ?.filter(({ isChecked }) => isChecked)
          ?.reduce((acc, reason) => [...acc, reason.id], [])
      );
    },
    [reasonList, setReasonList, setReasonFilters, reasonFilters]
  );

  const handleClearSelectedReason = useCallback(() => {
    const updatedReasonList = reasonList.map((reason) => ({
      ...reason,
      isChecked: false,
    }));
    setReasonList(updatedReasonList);
    setReasonFilters([]);
  }, [reasonList, setReasonList, setReasonFilters]);

  const handleChangeSelectedResponsible = useCallback(
    (id) => {
      const updatedResponsible = responsibleReasonList.map((responsible) =>
        responsible.id === id
          ? { ...responsible, isChecked: !responsible.isChecked }
          : responsible
      );
      setResponsibleReasonList(updatedResponsible);
      setResponsibleFilters(
        updatedResponsible
          ?.filter(({ isChecked }) => isChecked)
          ?.reduce((acc, responsible) => [...acc, responsible.id], [])
      );
    },
    [
      responsibleReasonList,
      setResponsibleReasonList,
      setResponsibleFilters,
      responsibleFilters,
    ]
  );

  const handleClearSelectedResponsible = useCallback(() => {
    const updatedResponsible = responsibleReasonList.map((responsible) => ({
      ...responsible,
      isChecked: false,
    }));
    setResponsibleReasonList(updatedResponsible);
    setResponsibleFilters([]);
  }, [
    responsibleReasonList,
    setResponsibleReasonList,
    setResponsibleFilters,
    responsibleFilters,
  ]);

  const handleChangeSelectedOrderBy = (value) => {
    setSelectedOrderBy(value);
  };

  const handleChangeSelectedOrder = (value) => {
    setSelectedOrder(value);
  };

  const handleChangeSelectedClassifier = useCallback(
    (id) => {
      const updatedClassifier = classifierList.map((classifier) =>
        classifier.id === id
          ? { ...classifier, isChecked: !classifier.isChecked }
          : classifier
      );
      setClassifierList(updatedClassifier);
      setClassifierFilters(
        updatedClassifier
          ?.filter(({ isChecked }) => isChecked)
          ?.reduce((acc, classifier) => [...acc, classifier.id], [])
      );
    },
    [classifierList, setClassifierList, setClassifierFilters]
  );

  const handleClearSelectedClassifier = useCallback(() => {
    const updatedClassifier = classifierList.map((classifier) => ({
      ...classifier,
      isChecked: false,
    }));
    setClassifierList(updatedClassifier);
    setClassifierFilters([]);
  }, [classifierList, setClassifierList, setClassifierFilters]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <ContainerPage>
      <LoadingBackDrop open={submitLoading} />
      <DivTitle>
        <H1>Análise de Quebras</H1>
      </DivTitle>
      <DivSearch>
        <SearchInput
          inputRef={searchText}
          name="searchText"
          variant="outlined"
          placeholder="Nº do pedido ou nome do produto"
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          autoComplete="off"
          onChange={handleSearch}
        />
        <SelectDateSearch
          selectedSearchDateFrom={selectedSearchDateFrom}
          selectedSearchDateTo={selectedSearchDateTo}
          handleChangeSearchDateFrom={handleChangeSearchDateFrom}
          handleChangeSearchDateTo={handleChangeSearchDateTo}
        />
      </DivSearch>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="accordion-filters"
        >
          <FilterContentHeader>
            <FilterTitleLabel>Filtros</FilterTitleLabel>
            <FilterLabel>
              Exibindo:{" "}
              <FilterLabelDescription>
                {statusReference[statusFilters]}
              </FilterLabelDescription>
            </FilterLabel>

            <FilterLabel>
              Produto base:{" "}
              <FilterLabelDescription>
                {selectedProductBase?.length} selecionado(s)
              </FilterLabelDescription>
            </FilterLabel>

            <FilterLabel>
              Motivo:{" "}
              <FilterLabelDescription>
                {reasonFilters?.length} selecionado(s)
              </FilterLabelDescription>
            </FilterLabel>
            <FilterLabel>
              Responsável:{" "}
              <FilterLabelDescription>
                {responsibleFilters?.length} selecionado(s)
              </FilterLabelDescription>
            </FilterLabel>
            <FilterLabel>
              Classificador:{" "}
              <FilterLabelDescription>
                {classifierFilters?.length} selecionado(s)
              </FilterLabelDescription>
            </FilterLabel>
          </FilterContentHeader>
          <ClearFilterButtonContent>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleClearFilter();
              }}
            >
              Limpar
            </Button>
          </ClearFilterButtonContent>
        </AccordionSummary>
        <AccordionDetails>
          <FilterContainer>
            <FilterContent>
              <SelectStatus
                statusFilters={statusFilters}
                selectedProductBase={selectedProductBase}
                handleChangeStatusFilters={handleChangeStatusFilters}
                items={items}
              />

              <SelectProductsBase
                productsBase={productsBase}
                handleChangeSelectedProductBase={
                  handleChangeSelectedProductBase
                }
                items={items}
                statusFilters={statusFilters}
                reasonFilters={reasonFilters}
                handleClearSelectedProductBase={handleClearSelectedProductBase}
              />

              <SelectReason
                reasonList={reasonList}
                handleChangeSelectedReason={handleChangeSelectedReason}
                items={items}
                statusFilters={statusFilters}
                selectedProductBase={selectedProductBase}
                handleClearSelectedReason={handleClearSelectedReason}
              />

              <SelectResponsible
                responsibleReasonList={responsibleReasonList}
                handleChangeSelectedResponsible={
                  handleChangeSelectedResponsible
                }
                items={items}
                statusFilters={statusFilters}
                handleClearSelectedResponsible={handleClearSelectedResponsible}
              />
            </FilterContent>
            <FilterContent>
              <SelectClassifier
                classifierList={classifierList}
                items={items}
                handleChangeSelectedClassifier={handleChangeSelectedClassifier}
                handleClearSelectedClassifier={handleClearSelectedClassifier}
                statusFilters={statusFilters}
              />

              <SelectOrderBy
                selectedOrderBy={selectedOrderBy}
                handleChangeSelectedOrderBy={handleChangeSelectedOrderBy}
              />

              <SelectOrder
                selectedOrder={selectedOrder}
                handleChangeSelectedOrder={handleChangeSelectedOrder}
              />
            </FilterContent>
          </FilterContainer>
        </AccordionDetails>
      </Accordion>

      <CustomLineProgress hidden={!searchLoading} />
      <TableContainer>
        <CheckAllContent>
          <Checkbox
            checked={qtyCheckedItens > 1}
            onClick={(e) => handleCheckAllItem(e)}
          />
          {qtyCheckedItens > 1 && (
            <ClassifiedAllButton onClick={handleResolveSelected}>
              Classificar quebras selecionadas
            </ClassifiedAllButton>
          )}
        </CheckAllContent>

        <BreakTable
          items={listItems}
          handleOpenSeeMore={handleOpenSeeMore}
          handleCheckItem={handleCheckItem}
          handleCheckAllItem={handleCheckAllItem}
          qtyCheckedItens={qtyCheckedItens}
          handleResolveSelected={handleResolveSelected}
        />
      </TableContainer>

      <ModalBodySeeMore
        open={openSeeMore}
        close={handleCloseSeeMore}
        complaint={itemDetail}
        responsibleReasonList={responsibleReasonList}
        selectedResponsible={selectedResponsible}
        handleChangeResponsible={handleChangeResponsible}
        responsibleObservation={responsibleObservation}
        handleChangeResponsibleObservation={handleChangeResponsibleObservation}
        handleSubmit={handleSubmit}
        handleOpenImage={handleOpenImage}
      />
      <AlertModal
        handleClose={closeAlert}
        open={openAlert}
        text={`Salvo com sucesso!`}
      />

      <ModalImage
        open={openModalImage}
        url={itemDetail?.complaintImage}
        handleClose={handleCloseImage}
        alt="Produto"
      />

      <ResolveSelectedModal
        close={handleCloseResolveSelected}
        open={openResolveSelected}
        responsibleReasonList={responsibleReasonList}
        selectedResponsible={selectedResponsible}
        handleChangeResponsible={handleChangeResponsible}
        responsibleObservation={responsibleObservation}
        handleChangeResponsibleObservation={handleChangeResponsibleObservation}
        handleSubmit={handleSubmit}
      />
    </ContainerPage>
  );
};
